






import { Component, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'

@Component({
  components: {
    ModalBase,
  },
})
export default class ModalSchoolTest extends Mixins(ModalBaseMethod) {
  private title = ''

  private testRanges: string[] = []

  public async showSchoolTest(id: number, title: string, isReserve = false) {
    this.title = title
    Vue.prototype.$loading.start()
    await this.getTermExamsSettings(id, isReserve)
    Vue.prototype.$loading.complete()
  }

  private async getTermExamsSettings(id: number, isReserve: boolean) {
    const apiPath = isReserve ? `/term_exams/reserve/${id}/setting` : `/term_exams/${id}/setting`
    await Vue.prototype.$http.httpWithToken.get(apiPath).then((res: any) => {
      if (res.data.settings.length === 0) {
        alert('データが見つかりません。ページを更新してお確かめください。')
        return
      }
      this.testRanges = res.data.settings.map(
        (setting: { grade: string; startPage: number; endPage: number; sUnitName: string; publisherName: string }) => {
          if (res.data.settingIsItem) {
            return `${setting.grade} ${setting.sUnitName}(${setting.publisherName}: p${setting.startPage}-p${setting.endPage})`
          } else {
            return `${setting.grade} p${setting.startPage}-p${setting.endPage}`
          }
        }
      )
      this.show()
    })
  }
}
