











































import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import DatePickerIcon from '@/components/atoms/DatePickerIcon.vue'
import moment from 'moment'
import LocalMoment from '@/components/atoms/LocalMoment.vue'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
    TableBase,
    DatePickerIcon,
  },
})
export default class SchoolTestInformation extends Mixins(LocalMoment) {
  private tableBasePerPage: number | null = null

  private tableBaseFields: object[] = [
    { key: 'subject', label: '教科' },
    { key: 'range', label: 'テスト範囲確認' },
    { key: 'action', label: 'テスト範囲設定' },
  ]

  private updated() {
    this.scrollToHash()
  }

  private scrollToHash() {
    const hash = this.$route.hash
    if (hash) {
      const refName = hash.replace('#', '')
      const el = this.$refs[refName] as HTMLElement
      const rectTop = el[0].getBoundingClientRect().top
      window.scrollTo({ top: rectTop + window.pageYOffset - 100, behavior: 'smooth' })
    }
  }

  private changeAlert(testData: any) {
    const confirm = window.confirm(
      '変更した内容は翌日から適用されます。\nまた、テスト範囲を変更した場合、ステップ進行度がリセットされます。\nよろしいですか？'
    )
    if (!confirm) {
      // キャンセル時
      return
    }

    // OK押下時は変更中フラグを立てる
    this.$router.replace({ hash: testData.gradeCode, query: { termId: this.$route.query.termId } })
    Vue.set(testData, 'changing', true)
    // 各ボタンのdisabled解除
    testData.items.forEach((item: any) => {
      item.action.forEach((action: any) => {
        action.buttonDisabled = false
      })
    })
  }

  private tableBaseButtons: string[] = ['range', 'action']

  @Prop()
  testDatas!: []

  @Prop()
  onChange!: Function

  private setFuncParams(testData: { schoolId: number; gradeId: number; termId: number; gradeCode: string }) {
    return {
      schoolId: testData.schoolId,
      gradeId: testData.gradeId,
      termId: testData.termId,
      gradeCode: testData.gradeCode,
    }
  }

  private async onChangeRangeDate(params: {
    schoolId: number
    gradeId: number
    termId: number
    gradeCode: string
    start: moment.Moment
    end: moment.Moment
  }) {
    if (params.start > params.end) {
      alert('選択期間が不正です')
      return
    }
    this.$router.replace({ hash: params.gradeCode, query: { termId: this.$route.query.termId } })
    await this.updateRangeDate(params)
  }

  private async updateRangeDate(params: {
    schoolId: number
    gradeId: number
    termId: number
    start: moment.Moment
    end: moment.Moment
  }) {
    Vue.prototype.$loading.start()
    await this.updateTermExams({
      schoolId: params.schoolId,
      gradeId: params.gradeId,
      termId: params.termId,
      from: params.start.format('YYYY-MM-DD'),
      to: params.end.format('YYYY-MM-DD'),
    })
    Vue.prototype.$loading.complete()
  }

  // テスト対策期間を更新する
  private async updateTermExams(update_params: {
    schoolId: number
    gradeId: number
    termId: number
    from: string
    to: string
  }) {
    await Vue.prototype.$http.httpWithToken
      .patch('/term_exams', update_params)
      .catch((error: any) => {
        if (error.response.data.status === 422) {
          alert(
            'テスト対策期間が不正です。開始日には当日以降を設定してください。他対策期間と重なる日付は設定出来ません。開始日と終了日に同じ日付は設定できません。'
          )
        } else {
          alert('エラーが発生しました。ページを更新してください。')
        }
      })
      .finally(() => {
        this.onChange()
      })
  }

  //--- 変更予約系の処理

  // 引数設定
  private setReserveFuncParams(testData: {
    schoolId: number
    gradeId: number
    termId: number
    period_date: { start: moment.Moment }
  }) {
    return {
      schoolId: testData.schoolId,
      gradeId: testData.gradeId,
      termId: testData.termId,
      start: moment(testData.period_date.start),
    }
  }

  private async onChangeReserveDate(params: {
    schoolId: number
    gradeId: number
    termId: number
    start: moment.Moment
    date: moment.Moment
  }) {
    if (params.start > params.date) {
      alert('選択期間が不正です')
      return
    }
    await this.updateReserveDate(params)
  }

  private async updateReserveDate(params: {
    schoolId: number
    gradeId: number
    termId: number
    start: moment.Moment
    date: moment.Moment
  }) {
    Vue.prototype.$loading.start()
    await this.updateReserveTermExams({
      schoolId: params.schoolId,
      gradeId: params.gradeId,
      termId: params.termId,
      from: params.start.format('YYYY-MM-DD'),
      to: params.date.format('YYYY-MM-DD'),
    })
    Vue.prototype.$loading.complete()
  }

  // テスト対策期間を更新する
  private async updateReserveTermExams(update_params: {
    schoolId: number
    gradeId: number
    termId: number
    from: string
    to: string
  }) {
    await Vue.prototype.$http.httpWithToken
      .patch('/term_exams/reserve', update_params)
      .catch((error: any) => {
        if (error.response.data.status === 422) {
          alert(
            'テスト対策期間が不正です。開始日には当日以降を設定してください。他対策期間と重なる日付は設定出来ません。開始日と終了日に同じ日付は設定できません。'
          )
        } else {
          alert('エラーが発生しました。ページを更新してください。')
        }
      })
      .finally(() => {
        this.onChange()
      })
  }

  // 変更予約可能かを判定
  private canChangeReserve(testData: any): boolean {
    // 変更可能な場合は即時修正可能なので予約不可
    if (testData.canEdit) {
      return false
    }

    // 期間終了前の場合のみ変更可
    if (this.afterEqualThanToday(testData.period_date.end)) {
      return true
    }

    return false
  }

  // 日付の表示フォーマット設定
  private formatDate(from: Date | null, to: Date | null): string {
    if (!from || !to) return '未設定'
    const start = moment(from).locale('ja')
    const end = moment(to).locale('ja')
    return (
      start.format('LL') +
      '~' +
      (start.format('YYYY') === end.format('YYYY') ? end.format('MM月DD日') : end.format('LL'))
    )
  }
}
