











import { Component, Vue, Ref, Watch, Mixins } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import TabBase from '@/components/atoms/TabBase.vue'
import SchoolTestInformation from '@/components/organisms/SchoolTestInformation.vue'
import ModalSchoolTest from '@/components/organisms/ModalSchoolTest.vue'
import moment from 'moment'
import LocalMoment from '@/components/atoms/LocalMoment.vue'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    TabBase,
    SchoolTestInformation,
    ModalSchoolTest,
  },
})
export default class SchoolTest extends Mixins(LocalMoment) {
  private schoolId = this.$route.params.id

  private breadcrumbs = [
    { text: '設定', href: '/teacher/setting/top' },
    { text: '学校設定', href: '/teacher/setting/school' },
    { text: '', active: true },
  ]

  private termsTabDatas = [
    { id: 1, name: '1学期中間' },
    { id: 2, name: '1学期期末' },
    { id: 3, name: '2学期中間' },
    { id: 4, name: '2学期期末' },
    { id: 5, name: '3学期中間' },
    { id: 6, name: '3学期期末' },
  ]

  private termId = Number(this.$route.query.termId) || 1

  private testDatas: object[] = []

  @Ref() modalSchoolTest!: ModalSchoolTest

  @Watch('termId')
  onTermIdChanged() {
    this.loadData()
  }

  private showSchooltest(params: { id: number; title: string; isReserve?: boolean }): void {
    this.modalSchoolTest.showSchoolTest(params.id, params.title, params.isReserve)
  }

  private async loadTermExams() {
    const params = [`schoolId=${this.schoolId}`, `termId=${this.termId}`]

    await Vue.prototype.$http.httpWithToken
      .get(`/term_exams?${params.join('&')}`)
      .then((res: any) => {
        this.breadcrumbs[this.breadcrumbs.length - 1].text = res.data.schoolName + '（テスト）'
        this.testDatas = res.data.termExamsGrades.map(
          (termExamsGrade: {
            gradeId: number
            gradeName: string
            gradeCode: string
            from: Date
            to: Date
            changeReservedFrom: Date
            changeReservedTo: Date
            termExams: []
          }) => {
            const items = termExamsGrade.termExams.map(
              (termExam: {
                id: number
                subjectName: string
                settingExists: boolean
                settingIsItem: boolean
                changeReserved: boolean
                reservedIsItem: boolean
              }) => {
                return {
                  subject: termExam.subjectName,
                  range: this.rangeButton(termExam, termExamsGrade.gradeName),
                  action: this.actionButtons(termExam, termExamsGrade.from, termExamsGrade.gradeCode),
                }
              }
            )
            return {
              grade: termExamsGrade.gradeName,
              period: this.setRangeDate(termExamsGrade.from, termExamsGrade.to),
              period_date: this.setRangeDateParam(termExamsGrade.from, termExamsGrade.to),
              periodReserved: this.setRangeDate(termExamsGrade.changeReservedFrom, termExamsGrade.changeReservedTo),
              periodReservedDate: this.setRangeDateParam(
                termExamsGrade.changeReservedFrom,
                termExamsGrade.changeReservedTo
              ),
              items: items,
              gradeId: termExamsGrade.gradeId,
              gradeCode: termExamsGrade.gradeCode,
              schoolId: this.schoolId,
              termId: this.termId,
              canEdit: this.canEdit(termExamsGrade.from, true),
            }
          }
        )
      })
      .catch((error: any) => {
        Vue.prototype.$logger.error(error)
      })
  }

  private rangeButton(
    termExam: {
      id: number
      subjectName: string
      settingExists: boolean
      settingIsItem: boolean
      changeReserved: boolean
      reservedIsItem: boolean
    },
    gradeName: string
  ) {
    // 配列を定義
    const buttons = []

    // 現在の指定状況
    if (termExam.settingExists) {
      // 設定済み
      buttons.push({
        onclick: this.showSchooltest,
        variable: {
          id: termExam.id,
          title: `${gradeName} ${this.getTermName()} ${termExam.subjectName}`,
          isReserve: false,
        },
        name: termExam.settingIsItem ? '項目指定' : 'ページ指定',
      })
    } else {
      // 未設定
      buttons.push({ name: '未設定', buttonDisabled: true })
    }

    // 変更予約中の情報
    if (termExam.changeReserved) {
      buttons.push({
        onclick: this.showSchooltest,
        variable: {
          id: termExam.id,
          title: `${gradeName} ${this.getTermName()} ${termExam.subjectName}`,
          isReserve: true,
        },
        name: termExam.reservedIsItem ? '項目指定(変更予約中)' : 'ページ指定(変更予約中)',
      })
    }
    return buttons
  }

  private actionButtons(
    termExam: { id: number; settingExists: boolean; changeReserved: boolean },
    from: Date,
    gradeCode: string
  ) {
    const buttonDisabled = !this.canEdit(from, termExam.settingExists)
    return [
      {
        url: `/teacher/setting/school-test/${this.schoolId}/term/${this.termId}/page/${termExam.id}`,
        name: 'ページ指定',
        buttonDisabled: buttonDisabled,
      },
      {
        url: `/teacher/setting/school-test/${this.schoolId}/term/${this.termId}/unit/${termExam.id}`,
        name: '項目指定',
        buttonDisabled: buttonDisabled,
      },
      {
        onclick: this.reset,
        variable: {
          id: termExam.id,
          settingExists: termExam.settingExists,
          changeReserved: termExam.changeReserved,
          reserveMode: buttonDisabled,
          gradeCode: gradeCode,
        },
        name: 'リセット',
        buttonDisabled: buttonDisabled,
      },
    ]
  }

  private setRangeDate(from: Date | null, to: Date | null): string {
    if (!from || !to) return '未設定'
    const start = moment(from).locale('ja')
    const end = moment(to).locale('ja')
    return (
      start.format('LL') +
      '~' +
      (start.format('YYYY') === end.format('YYYY') ? end.format('MM月DD日') : end.format('LL'))
    )
  }

  private setRangeDateParam(from: Date | null, to: Date | null): object {
    if (!from || !to) return { start: null, end: null }
    return { start: moment(from).locale('ja').toDate(), end: moment(to).locale('ja').toDate() }
  }

  private canEdit(from: Date | null, settingExists: boolean): boolean {
    if (!from) return true
    return this.afterThanToday(from) || (this.equalToday(from) && !settingExists)
  }

  private getTermName(): string {
    const term = this.termsTabDatas.find((termsTabData) => {
      return termsTabData.id === this.termId
    })
    if (!term) return ''
    return term.name
  }

  private async destroyTermExamsSetting(params: { id: number; settingExists: boolean }) {
    if (!params.settingExists) {
      alert('テスト範囲が未設定です。')
      return
    }
    if (!confirm('リセットしますか？')) return

    await Vue.prototype.$http.httpWithToken
      .delete(`/term_exams/${params.id}/setting`)
      .then(() => {
        this.loadTermExams()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        } else {
          alert('エラーが発生しました。ページを更新してお確かめください。')
        }
      })
  }

  private async destroyReservedTermExamsSetting(params: { id: number; changeReserved: boolean }) {
    if (!params.changeReserved) {
      alert('テスト範囲変更が未予約です。')
      return
    }
    if (!confirm('リセットしますか？')) return

    await Vue.prototype.$http.httpWithToken
      .delete(`/term_exams/reserve/${params.id}/setting`)
      .then(() => {
        this.loadTermExams()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        } else {
          alert('エラーが発生しました。ページを更新してお確かめください。')
        }
      })
  }

  private async reset(params: {
    id: number
    settingExists: boolean
    changeReserved: boolean
    reserveMode: boolean
    gradeCode: string
  }) {
    Vue.prototype.$loading.start()
    this.$router.replace({ hash: params.gradeCode, query: { termId: this.$route.query.termId } })
    // 変更予約か否かによって分岐
    if (params.reserveMode) {
      await this.destroyReservedTermExamsSetting(params)
    } else {
      await this.destroyTermExamsSetting(params)
    }
    Vue.prototype.$loading.complete()
  }

  private async loadData() {
    Vue.prototype.$loading.start()
    await this.loadTermExams()
    Vue.prototype.$loading.complete()
  }

  private async mounted() {
    this.loadData()
  }
}
